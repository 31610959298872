import React, { Component, createContext } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext({});

class AppProvider extends Component {
  state = {
    loaded: false,
    menuActive: false
  };

  //

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(`%c <3 + $ `, `background: #000000; color: #00ff00`);
  }

  //

  setLoaded = loaded => {
    this.setState({
      loaded
    });
  };

  setMenuActive = menuActive => {
    this.setState({
      menuActive
    });
  };

  //

  render() {
    return (
      <AppContext.Provider
        value={{
          loaded: this.state.loaded,
          menuActive: this.state.menuActive,
          //
          setLoaded: this.setLoaded,
          setMenuActive: this.setMenuActive
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
